import { isEqual, pick } from 'lodash';

import { FILTER_KEYS_TO_COUNT, initialFilterState, GEO_FILTER_KEYS } from './config';
import { FilterState } from './interfaces';
import { DEFAULT_DURATIONS, DEFAULT_SHORT_DURATIONS } from '../../util/durations';
import { GlobalFiltersState } from '../globalFiltersStore';

export function getSelectedFilterCount(filters: FilterState): number {
	const filteredFilters = pick(filters, FILTER_KEYS_TO_COUNT);

	return Object.keys(filteredFilters).filter(
		(filterKey) =>
			!isEqual(
				filteredFilters[filterKey as keyof typeof filteredFilters],
				initialFilterState[filterKey as keyof typeof filteredFilters],
			),
	).length;
}

export function getClearedFilters(filters: FilterState): FilterState {
	/**
	 * Set all filters to count to their initial state
	 * add the geo and chain filters to the list of cleared filters
	 */
	return [...FILTER_KEYS_TO_COUNT, ...GEO_FILTER_KEYS, 'chain', 'tourOperator'].reduce<FilterState>((state, type) => {
		if (state[type as keyof FilterState]) {
			(state[type as keyof FilterState] as FilterState[keyof FilterState]) =
				initialFilterState[type as keyof FilterState];
		}

		return state;
	}, filters);
}

export function getSelectedDurations(globalFilters: GlobalFiltersState, filters: FilterState): string | number[] {
	if (globalFilters.durations?.length) {
		return globalFilters.durations;
	}

	if (isAccoWithShortDuration(filters)) {
		return DEFAULT_SHORT_DURATIONS;
	}

	return DEFAULT_DURATIONS;
}

export function isAccoWithShortDuration(filters: FilterState): boolean {
	return filters.domain === 'city_trip' || filters.campaign === 'stedentrip' || filters.transport === 'OWN';
}

export function isDefaultDurations(durations: GlobalFiltersState['durations'], filters: FilterState): boolean {
	if (!durations) {
		return false;
	}

	if (isAccoWithShortDuration(filters)) {
		return durations === DEFAULT_SHORT_DURATIONS;
	}

	return durations === DEFAULT_DURATIONS;
}
